 import style from "./App.module.scss";

 const Snowflake = (props) => {
    return(
        <p className={style.Snowflake} id={`item${props.id}`} style={props.style}>
            *
        </p>
    )
 }

 export default Snowflake;